import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../utils/connectors";
import { isNoEthereumObject } from "../../utils/errors";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { API } from "../../configs/api";
import I_metaMask from "../../img/I_metaMask.svg";


export default function SignIn(): JSX.Element {
  const { account, active, activate, deactivate } = useWeb3React();

  if (account) {
    let adminAddress;
    let acc = account.toLocaleLowerCase();
    let isAdmin = false;
    var getAdminAddress = async () => {
      axios.get(API.API_ADMIN_ADDRESS_GET + `/1/0/120/id/DESC`).then((resp) => {
        let { status, list } = resp.data;
        if (status == "OK") {
          let aaddress = list[0].address;
          adminAddress = aaddress.split(',');
          
          for(var i=0; i<adminAddress.length; i++) {
            if(adminAddress[i] == acc) {
              isAdmin = true;
              break;
            }
          }

          if(isAdmin) {
            window.location.href = '/#/user-managing';
          } else {
            window.location.href = 'https://nftinfinity.world/';
          }
        }
      });
    };
    getAdminAddress();
  };

  const handleConnect = () => {
    if (active) {
      deactivate();
      return;
    }
    activate(injected, (error) => {
      if (isNoEthereumObject(error))
        window.open("https://metamask.io/download.html");
    });
  };

  return (
      <div className="connect">
        <button className='signinBtn' type="button" onClick={handleConnect}
          style={{
            marginTop:'100%',
            width: '168px',
            height: '168px',
            borderRadius: '12px',
            cursor:'pointer',
            background:'#fff',
            border:'1px solid #eee'
          }}
        >
          <img src={I_metaMask} alt=""/>
        </button>
      </div>
  )
}