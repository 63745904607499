import { TextareaAutosize } from '@mui/material'
import { InputAdornment, OutlinedInput } from '@mui/material'
import {useRef, useEffect} from 'react';


export const siteManagementBody = [
  // {
  //   content: () => {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '1rem',
  //         }}
  //       >
  //         <article style={{ width: '30%' }}>
  //           MBCC 스왑, 스테이킹 차감 적용
  //         </article>
  //         <article style={{ width: '70%' }}>
  //           MBCC 몬스터는 최고가 도달 전{' '}
  //           <OutlinedInput
  //             placeholder="0"
  //             id="outlined-adornment-weight"
  //             endAdornment={<InputAdornment position="end">%</InputAdornment>}
  //             aria-describedby="outlined-weight-helper-text"
  //             inputProps={{
  //               'aria-label': 'weight',
  //             }}
  //             sx={{
  //               width: '100px',
  //               height: '38px',
  //               borderRadius: '12px',
  //               marginLeft: '5px',
  //               marginRight: '5px',
  //             }}
  //           />
  //           의 스왑, 스테이킹 토큰을 적용합니다.
  //         </article>
  //       </div>
  //     )
  //   },
  // },
  // {
  //   content: () => {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '1rem',
  //         }}
  //       >
  //         <article style={{ width: '30%' }}>
  //           스왑 후 다음 스왑 가능 일자
  //         </article>
  //         <article style={{ width: '70%' }}>
  //           <OutlinedInput
  //             placeholder="0"
  //             id="outlined-adornment-weight"
  //             aria-describedby="outlined-weight-helper-text"
  //             inputProps={{
  //               'aria-label': 'weight',
  //             }}
  //             sx={{
  //               width: '100px',
  //               height: '38px',
  //               borderRadius: '12px',
  //               marginLeft: '5px',
  //               marginRight: '5px',
  //             }}
  //           />
  //           일 이후 (ex: 7일 세팅시 7일째부터 스왑이 가능)
  //         </article>
  //       </div>
  //     )
  //   },
  // },
  // {
  //   content: () => {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '1rem',
  //         }}
  //       >
  //         <article style={{ width: '30%' }}>
  //           스왑 가능한 최소 몬스터 가격
  //         </article>
  //         <article style={{ width: '70%' }}>
  //           <OutlinedInput
  //             placeholder="0"
  //             id="outlined-adornment-weight"
  //             aria-describedby="outlined-weight-helper-text"
  //             endAdornment={<InputAdornment position="end">$</InputAdornment>}
  //             inputProps={{
  //               'aria-label': 'weight',
  //             }}
  //             sx={{
  //               width: '100px',
  //               height: '38px',
  //               borderRadius: '12px',
  //               marginLeft: '5px',
  //               marginRight: '5px',
  //             }}
  //           />
  //         </article>
  //       </div>
  //     )
  //   },
  // },
  // {
  //   content: () => {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '1rem',
  //         }}
  //       >
  //         <article style={{ width: '30%' }}>MBCC 시세</article>
  //         <article style={{ width: '70%' }}>
  //           <OutlinedInput
  //             placeholder="0"
  //             id="outlined-adornment-weight"
  //             aria-describedby="outlined-weight-helper-text"
  //             inputProps={{
  //               'aria-label': 'weight',
  //             }}
  //             sx={{
  //               width: '100px',
  //               height: '38px',
  //               borderRadius: '12px',
  //               marginLeft: '5px',
  //               marginRight: '5px',
  //             }}
  //           />
  //           <span style={{ color: '#7A7A7A' }}>갱신일시: 2022.01.18</span>
  //         </article>
  //       </div>
  //     )
  //   },
  // },
  // {
  //   content: () => {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '1rem',
  //         }}
  //       >
  //         <article style={{ width: '30%' }}>메인 매도, 매수 그래프</article>
  //         <article style={{ width: '70%' }}></article>
  //       </div>
  //     )
  //   },
  // },
  // {
  //   content: () => {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '1rem',
  //         }}
  //       >
  //         <article style={{ width: '30%' }}>경매장 타이틀</article>
  //         <article style={{ width: '70%' }}>
  //           <OutlinedInput
  //             id="outlined-adornment-weight"
  //             aria-describedby="outlined-weight-helper-text"
  //             inputProps={{
  //               'aria-label': 'weight',
  //             }}
  //             sx={{
  //               width: '100%',
  //               height: '38px',
  //               borderRadius: '12px',
  //               marginLeft: '5px',
  //               marginRight: '5px',
  //             }}
  //           />
  //         </article>
  //       </div>
  //     )
  //   },
  // },
  // {
  //   content: () => {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '1rem',
  //         }}
  //       >
  //         <article style={{ width: '30%' }}>경매장 서브 타이틀</article>
  //         <article style={{ width: '70%' }}>
  //           <OutlinedInput
  //             id="outlined-adornment-weight"
  //             aria-describedby="outlined-weight-helper-text"
  //             inputProps={{
  //               'aria-label': 'weight',
  //             }}
  //             sx={{
  //               width: '100%',
  //               height: '38px',
  //               borderRadius: '12px',
  //               marginLeft: '5px',
  //               marginRight: '5px',
  //             }}
  //           />
  //         </article>
  //       </div>
  //     )
  //   },
  // },
  // {
  //   content: () => {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '1rem',
  //         }}
  //       >
  //         <article style={{ width: '30%' }}>매칭 타이틀</article>
  //         <article style={{ width: '70%' }}>
  //           <OutlinedInput
  //             id="outlined-adornment-weight"
  //             aria-describedby="outlined-weight-helper-text"
  //             inputProps={{
  //               'aria-label': 'weight',
  //             }}
  //             sx={{
  //               width: '100%',
  //               height: '38px',
  //               borderRadius: '12px',
  //               marginLeft: '5px',
  //               marginRight: '5px',
  //             }}
  //           />
  //         </article>
  //       </div>
  //     )
  //   },
  // },
  // {
  //   content: () => {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '1rem',
  //         }}
  //       >
  //         <article style={{ width: '30%' }}>매칭 서브 타이틀</article>
  //         <article style={{ width: '70%' }}>
  //           <OutlinedInput
  //             id="outlined-adornment-weight"
  //             aria-describedby="outlined-weight-helper-text"
  //             inputProps={{
  //               'aria-label': 'weight',
  //             }}
  //             sx={{
  //               width: '100%',
  //               height: '38px',
  //               borderRadius: '12px',
  //               marginLeft: '5px',
  //               marginRight: '5px',
  //             }}
  //           />
  //         </article>
  //       </div>
  //     )
  //   },
  // },
  // {
  //   content: () => {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '1rem',
  //         }}
  //       >
  //         <article style={{ width: '30%' }}>갤러리 타이틀</article>
  //         <article style={{ width: '70%' }}>
  //           <OutlinedInput
  //             id="outlined-adornment-weight"
  //             aria-describedby="outlined-weight-helper-text"
  //             inputProps={{
  //               'aria-label': 'weight',
  //             }}
  //             sx={{
  //               width: '100%',
  //               height: '38px',
  //               borderRadius: '12px',
  //               marginLeft: '5px',
  //               marginRight: '5px',
  //             }}
  //           />
  //         </article>
  //       </div>
  //     )
  //   },
  // },
  // {
  //   content: () => {
  //     return (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '1rem',
  //         }}
  //       >
  //         <article style={{ width: '30%' }}>갤러리 서브 타이틀</article>
  //         <article style={{ width: '70%' }}>
  //           <OutlinedInput
  //             id="outlined-adornment-weight"
  //             aria-describedby="outlined-weight-helper-text"
  //             inputProps={{
  //               'aria-label': 'weight',
  //             }}
  //             sx={{
  //               width: '100%',
  //               height: '38px',
  //               borderRadius: '12px',
  //               marginLeft: '5px',
  //               marginRight: '5px',
  //             }}
  //           />
  //         </article>
  //       </div>
  //     )
  //   },
  // },
  {
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '1rem',
          }}
        >
          <article style={{ width: '30%' }}>관리자 로그인 기능 MATAMASK ADDRESS</article>
          <div style={{ width: '70%' }}>
            <article>
              <TextareaAutosize
                aria-label="empty textarea"
                id='textareaval'
                style={{
                  width: '100%',
                  height: '110px',
                  padding:'10px',
                  borderRadius: '12px',
                  marginLeft: '5px',
                  borderColor: '#D9D9D9',
                }}
              />
            </article>
            <article style={{ color: '#FF5050', fontSize: '14px' }}>
            MATAMASK ADDRESS별 구분은 줄바꿈으로 설정해주세요.
            </article>
          </div>
        </div>
      )
    },
  },
]
