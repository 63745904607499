import React from 'react'
import Papers from '../../components/paper/Papers'
import Button from '@mui/material/Button'
import { useState, useEffect } from "react";
import { jackpotlist } from '../../components/paper/fields/jackpot-num'
import PaperBodyContent from '../../components/paper/PaperBodyContent'
import axios from "axios";
import { API } from "../../configs/api";

const onclickjackpotbutton = async () => {
    var textVal = (document.getElementById('textareaval') as HTMLInputElement).value;
    var jackpotVal = '';
    if(textVal != '') {
      alert('저장되었습니다.');
      jackpotVal = textVal;
      await axios
      .post(API.API_JACKPOT_NUM, {
        jackpotVal
      });
    } else {
      alert('JACKPOT 당첨번호를 입력해주세요.');
    }
};

const getJackpotNum = async () => {
  axios.get(API.API_JACKPOT_NUM_GET + `/1/0/120/id/DESC`).then((resp) => {
    let { status, list } = resp.data;
    if (status == "OK") {
      let jackpotVal = list[0].num;
      (document.getElementById('textareaval') as HTMLInputElement).value = jackpotVal;
    }
  });
  
};


const Jackpotnum = () => {
  useEffect(() => {
    getJackpotNum();
    return () => {};
  }, []);
  return (
    <>
      <Papers title="사이트 관리">
        <section>
          <div>
            <PaperBodyContent fields={jackpotlist} />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
            sx={{
              width: '162px',
              height: '44px',
              bgcolor: 'text.disabled',
              color: 'black',
            }}
            onClick={() => {
              onclickjackpotbutton();
            }}
            >저장</Button>
          </div>
        </section>
      </Papers>
    </>
  )
}

export default Jackpotnum
