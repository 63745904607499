import * as React from 'react'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import MuiDrawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Routers from '../../Routers'
import nip_logo from '../../assets/icon/nip-logo.svg'
import DrawerField from '../../components/drawer-items/DrawerField'
import { drawerFields } from '../../components/drawer-items/items/drawer-fields'
import navi_logo from '../../assets/images/navi-logo.png'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { useWeb3React } from "@web3-react/core";



function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://nftinfinity.world">
        NIP
      </Link>
      {new Date().getFullYear()}
    </Typography>
  )
}


let isAdmin = 'none';

  

const drawerWidth: number = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    display:isAdmin,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

const mdTheme = createTheme()

function DashboardContent() {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const { account, active, activate, deactivate } = useWeb3React();
if(active) {
  isAdmin = 'block';  
} else {
  isAdmin = 'none';
  console.log(window.location.href);
  let currentpath = window.location.href.split('/#')[1];
  if(currentpath == undefined) {
    currentpath = '/';
  }
  if(currentpath != '/') {
    window.location.href = '/';
  }
}

  const onClickGoRootPage = () => {
    navigate('/')
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <Drawer variant="permanent" open={open}
         sx={{
          overflowY:'scroll',
          height:'100vh'
        }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: [1],
            }}
          >
            <img src={nip_logo} alt="nip-logo" />
            <h1
              onClick={onClickGoRootPage}
              style={{ marginLeft: '6px', cursor: 'pointer' }}
            >
              NIP
            </h1>
          </Toolbar>

          {drawerFields.map((field, index) => (
            <List key={index}>
              <DrawerField
                title={field.title}
                setting_icon={field.icon}
                down_arrow_icon={field.arrow}
                childItems={field.child}
              />
              
            </List>
            
          ))}
          
          <div className="exLink" style={{ padding : '8px 16px', display: 'flex' }}>
            <img src="/static/media/roi-icon.d3a828f5.svg" style={{ marginLeft : '6px'}}/>
            <a href='http://43.201.125.215/#/home' target={'_blank'} style={{ textDecoration:'none',  marginTop: '4px', marginBottom: '4px', marginLeft: '1.5rem', color:'rgba(0, 0, 0, 0.87)'}}>NIP EXCHANGE</a>
          </div>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            width: '100%',
          }}
        >

          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Routers />
            <Copyright sx={{ pt: 4, paddingBottom: '10px' }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <DashboardContent />
}