import { Input, TextareaAutosize } from '@mui/material'
import { InputAdornment, OutlinedInput } from '@mui/material'
import {useRef, useEffect} from 'react';


export const jackpotlist = [
  {
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '1rem',
          }}
        >
          <article style={{ width: '30%' }}>JACKPOT 당첨번호</article>
          <div style={{ width: '70%' }}>
            <article>
              <input
                aria-label="empty textarea"
                type = "number"
                id='textareaval'
                min = '0'
                style={{
                  width: '100%',
                  padding:'10px',
                  marginLeft: '5px',
                  border:'1px solid #D9D9D9'
                }}
              />
            </article>
          </div>
        </div>
      )
    },
  },
]
