import React from 'react'
import Papers from '../../components/paper/Papers'
import Button from '@mui/material/Button'
import { useState, useEffect } from "react";
import { siteManagementBody } from '../../components/paper/fields/site-management-body'
import PaperBodyContent from '../../components/paper/PaperBodyContent'
import axios from "axios";
import { API } from "../../configs/api";

const onclickstakingbutton = async () => {
    var textVal = (document.getElementById('textareaval') as HTMLInputElement).value;
    var addressVal = '';
    if(textVal != '') {
      alert('저장되었습니다.');
      for(var i =0; i <textVal.split("\n").length; i++) {
        addressVal = addressVal + textVal.split("\n")[i].toLocaleLowerCase()+',';
      }
      addressVal = addressVal.slice(0, -1); 
      await axios
      .post(API.API_ADMIN_ADDRESS, {
        addressVal
      });
    } else {
      alert('메타마스크 ADDRESS를 입력해주세요.');
    }
};

const getAdminAddress = async () => {
  axios.get(API.API_ADMIN_ADDRESS_GET + `/1/0/120/id/DESC`).then((resp) => {
    let { status, list } = resp.data;
    if (status == "OK") {
      let aaddress = list[0].address;
      aaddress = String(aaddress).replace(/,/g, '\r\n');
      (document.getElementById('textareaval') as HTMLInputElement).value = aaddress;
    }
  });
  
};


const SiteManagement = () => {
  useEffect(() => {
    getAdminAddress();
    return () => {};
  }, []);
  return (
    <>
      <Papers title="사이트 관리">
        <section>
          <div>
            <PaperBodyContent fields={siteManagementBody} />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
            sx={{
              width: '162px',
              height: '44px',
              bgcolor: 'text.disabled',
              color: 'black',
            }}
            onClick={() => {
              onclickstakingbutton();
            }}
            >저장</Button>
          </div>
        </section>
      </Papers>
    </>
  )
}

export default SiteManagement
