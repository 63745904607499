// const URL = 'https://nftinfinity.world:34925' // nips1.net:34815, 34925'
import { net } from './net'

let URL

const url = {
    // ETH_TESTNET: 'https://nftinfinity.world:37593',
    //  ETH_TESTNET: 'https://nftinfinity.world:32939' , // 34935',
    // BSC_MAINNET: 'https://nftinfinity.world:32939' , // 34835',

    ETH_TESTNET: 'https://nftinfinity.world:37593', // 34935',
    BSC_MAINNET: 'https://nftinfinity.world:37593', // 34835',

}

URL = url[net]

const API = {
        API_TXS_STAKE: URL + '/queries/rows/logstakes/active/1', // /:offset/:limit/:orderkey/:orderval
        API_USERS: URL + '/queries/rows/users/nettype', // /:offset/:limit/:orderkey/:orderval
        API_USER_DETAIL: URL + '/users/info', // /:offset/:limit/:orderkey/:orderval
        API_GET_BALLOT: URL + '/queries/rows/ballots/nettype', // /:offset/:limit/:orderkey/:orderval
        API_COMMONITEMS: URL + '/queries/rows',
        API_COUNT: URL + '/queries/count',
        API_BALLOT: URL + '/queries/rows_v1/jsonobject/settings/key_/value_',
        API_PUTTIME: URL + '/queries/update-or-create-rows/settings',
        API_RECEIVABLES: `${URL}/queries/rows/receivables/nettype`,
        API_TRANSACTIONS: `${URL}/queries/rows/transactions/nettype`,
        API_LOGSALES: `${URL}/queries/rows/logsales/nettype`,
        API_DELINQUENCIES: `${URL}/queries/rows/delinquencies/nettype`,
        API_LOGDELINQUENTS: `${URL}/queries/rows/logdelinquents/nettype`,
        API_PUTSTATE: `${URL}/ballot/update-or-create-rows/settings`,
        API_LOGBALLOTS: `${URL}/queries/rows/logballots/active/1`,
        API_LOGROUNDS: `${URL}/queries/rows/logrounds/nettype`,
        API_MQ: `${URL}/ballot/mq`,
        API_BANNER: (offset, limit) => {
            return `${URL}/queries/rows/banners/active/1/${offset}/${limit}/id/DESC`
        },
        API_POST_BANNER: `${URL}/admin/banner`,
        API_GET_ITEMSTATS: `${URL}/itemstats`,
        API_SET_ACTIVE_USER: `${URL}/users/user_active`,
        API_GET_LOG_FEEPAYMENTS: `${URL}/queries/rows/logfeepayments/nettype`,
        API_CURRENT_ROUND_NUM: `${URL}/queries/singlerow/settings/key_/BALLOT_PERIODIC_ROUNDNUMBER`,
        API_CURRENT_ROUND_STATE: `${URL}/ballot/roundstate`,
        API_ALLOCATE_ITEMS: `${URL}/ballot/advance/roundstate`,
        API_INITIALIZE_ROUNDS: `${URL}/ballot/init/rounds`,
        API_MANUAL_PAYITEM: `${URL}/ballot/manual/payitem`,
        API_MANUAL_PAYDELINQUENCY: `${URL}/ballot/manual/paydelinquency`,
        API_ADMIN_ADDRESS: `${URL}/admin/adminaddress`,
        API_ADMIN_ADDRESS_GET: `${URL}/queries/rows/adminaddress/status`,

        API_LOGJACKPOTLIST: `${URL}/queries/rows/jackpotlist/nettype`,
        API_JACKPOT_NUM: `${URL}/jackpot/jackpot`,
        API_JACKPOT_NUM_GET: `${URL}/queries/rows/jackpotnum/status`,
    }
    // /rows/:tablename/:fieldname/:fieldval/:offset/:limit/:orderkey/:orderval
export { API }

// const URL='http://nip s1.net:348 15'
// const URL='http://3.35. 117.87:34 815'
/**  const API={
		API_MAX	: URL + `/queries/max` // /:tablename/:fieldname
	, API_TXS	: URL + '/transactions' // /:txhash
	, API_TICKERS : URL + '/tickers'
	, API_USERINFO : URL + '/users/info'
	, API_TOGGLE_FAVORITE : URL + '/favorites/toggle'
	, API_LOGIN : URL + '/users/login'

	, API_EMAIL_REQUEST : URL + '/signup/email/request'
	, API_SIGNUP : URL + '/signup/signup'
	, API_QUERY_REFERER : URL + '/queries/singlerow' // /:tablename/:fieldname/:fieldval
	, API_QUERY_USERADDRESS : URL + '/queries/singlerow' 
	, API_PREMIUMITEMS : URL + '/queries/rows'
	, API_COMMONITEMS  : URL + '/queries/rows'
	, API_ITEMDETAIL : URL + '/items/item' // /:itemid
}
export { 
	API
}
*/